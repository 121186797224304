// Global GA tracking function
function formTracking(form, category, action, target, validating) {

	// Default default values for function parameter
	category 		= typeof category 		!== 'undefined' ? category 		: 'direct-contact';
	action 			= typeof action 		!== 'undefined' ? action 		: 'information-request';
	target 			= typeof target 		!== 'undefined' ? target 		: '.email';
	validating 		= typeof validating 	!== 'undefined' ? validating 	: true;

	// Check if form is valid
	if(validating == true)
	{
		if($(form).valid()) {
			ga('send', 'event', { eventCategory: category, eventAction: action, eventLabel: $(target).val() });
		}
	}
	else
	{
		ga('send', 'event', { eventCategory: category, eventAction: action, eventLabel: $(target).val() });
	}
}

(function ($, window, document, undefined) {

	$(function () {

		// Remove no-js tag from HTML
		$('html').removeClass('no-js');

		$('.gh-collapse').on('click', function(e)
		{
			e.preventDefault();
			var nav = $('#nav');

			nav.toggleClass('gh-open');

			// On finishing the transition
			if(!nav.hasClass('gh-done'))
			{
				setTimeout(function() {
					nav.addClass('gh-done')
				}, 400)
			}
			else
			{
				nav.removeClass('gh-done')
			}
		});

		$('.facilities-content').matchHeight();
		$('.blog-summary').matchHeight();

		$('.content-image-slider').bxSlider({
			pager: false,
			nextText: '',
			prevText: '',
			auto: true,
			pause: 5000
		});

		$('.content-facilities-slider').bxSlider({
			pager: false,
			nextText: '',
			prevText: '',
			auto: true,
			pause: 10000
		});

		if (window.devicePixelRatio == 2) {

			var images = $('.retina');

			// loop through the images and make them hi-res
			for(var i = 0; i < images.length; i++) {
				// create new image name
				var imageType = images[i].src.substr(-4);
				var imageName = images[i].src.substr(0, images[i].src.length - 4);
				imageName += "@2x" + imageType;

				//rename image
				images[i].src = imageName;
			}
		}

		$('.form-datepicker').datepicker({
			dateFormat: 'dd-mm-yy',
			changeMonth: true,
			changeYear: true
		},
		$.datepicker.regional['nl']);

		// Validate all forms
	    $('form').each(function() {
			$(this).validate({
				// Also validate hidden
				ignore: [],

				highlight: function (element, errorClass, validClass) {
					var element = $(element),
						that = element;

					// if special parent
					if (element.closest('.input-wrapper').length)
					{
					    var that = element.closest('.input-wrapper');
					}
					else
					// customSelect field
					if (element.hasClass('hasCustomSelect'))
					{
						var that = element.siblings();
					}
					else
					// customUpload field
					if (element.attr('id') == 'form-attachment')
					{
						var that = element.siblings('.custom-upload');
					}

					that.addClass(errorClass).removeClass(validClass);
				},
				unhighlight: function (element, errorClass, validClass) {
					var element = $(element),
						that = element;

					// if special parent
					if (element.closest('.input-wrapper').length)
					{
					    var that = element.closest('.input-wrapper');
					}
					else
					// customSelect field
					if (element.hasClass('hasCustomSelect'))
					{
						var that = element.siblings();
					}
					else
					// customUpload field
					if (element.attr('id') == 'form-attachment')
					{
						var that = element.siblings('.custom-upload');
					}

					that.removeClass(errorClass).addClass(validClass);
				},

				// Don't show label
				errorElement: false,
				errorPlacement: function() {
					return true;
				}
			});
	    });

		// Embed Google Maps
		if ($('#google_maps').length !== 0) {
			var location = $('#google_maps').attr('data-location').split(',');
			var marker = $('#google_maps').attr('data-marker');
			var zoom = parseInt($('#google_maps').attr('data-zoom'));

			function init() {
				var mapOptions = {
					zoom: zoom,
					scrollwheel: false,
					center: new google.maps.LatLng(location[0],location[1]),
					panControl: false,
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					overviewMapControl: false
				};

				var mapElement = document.getElementById('google_maps');

				var map = new google.maps.Map(mapElement, mapOptions);

				if (marker) {
					var myLatLng = new google.maps.LatLng(location[0],location[1]);
					var beachMarker = new google.maps.Marker({
						position: myLatLng,
						map: map
					});
				}

				// Center Google Maps
				google.maps.event.addDomListener(window, "resize", function() {
					var center = map.getCenter();
					google.maps.event.trigger(map, "resize");
					map.setCenter(center);
				});
			}

			google.maps.event.addDomListener(window, 'load', init);
		}
	})


})(jQuery, window, document)
